// import { useQuery } from "@tanstack/react-query";
// import { MenusAPI, RolePermissionsAPI } from "api";
// import { useFetchMenus } from "hooks/useFetchMenu";
// import { useFetchRolesPermissions } from "hooks/useFetchPermissions";
// import { useEffect, useState } from "react";
// import { Spinner } from "react-bootstrap";

// export const useFilterAndSortMenuItems = () => {
//   const menuData = JSON.parse(localStorage.getItem("menus"));

//   console.log(menuData);

//   const userInfo = JSON.parse(localStorage.getItem("user"));
//   let roleId = userInfo ? userInfo.role : 0;

//   const { data: permissionsResponse, isLoading: isLoadingPermissions } =
//     useFetchRolesPermissions(roleId);

//   let permissionsData = permissionsResponse?.data.data ?? [];

//   // Filter permissions by role
//   const rolePermissions = permissionsData?.filter(
//     (permission) => permission.roleId === roleId
//   );

//   // Create a map of menuId to permissions
//   const menuPermissions = rolePermissions?.reduce((acc, permission) => {
//     acc[permission.menuId] = permission;
//     return acc;
//   }, {});

//   console.log("menu Permissions", rolePermissions);

//   // Separate parent and child menu items
//   const parents = menuData?.filter((menu) => menu.parentId === 0);
//   const children = menuData?.filter((menu) => menu.parentId !== 0);

//   // Map parent IDs to their children
//   const parentIdToChildren = parents?.reduce((acc, parent) => {
//     acc[parent.id] = children?.filter((child) => child.parentId === parent.id);
//     return acc;
//   }, {});

//   console.log("Parent Id to Children", parentIdToChildren);

//   // Filter out parents whose children all have allowView set to false
//   const visibleParents = parents?.filter((parent) => {
//     const children = parentIdToChildren[parent.id];
//     return children?.some((child) => menuPermissions[child.id]?.allowView);
//     // return children?.some((child) => menuPermissions[child.id]?.allowView);
//   });

//   console.log("Visible Parents", visibleParents);

//   // Filter children to only include those that are allowed to be viewed
//   const visibleChildren = children?.filter(
//     (child) => menuPermissions[child.id]?.allowView
//   );

//   // Map parent IDs to their visible children
//   const parentIdToVisibleChildren = visibleParents?.reduce((acc, parent) => {
//     acc[parent.id] = visibleChildren?.filter(
//       (child) => child.parentId === parent.id
//     );
//     return acc;
//   }, {});

//   // Sort the parent items
//   const sortedParents = visibleParents?.sort(
//     (a, b) => a.sortOrder - b.sortOrder
//   );

//   // Nest the child items under their respective parents
//   const nestedMenuItems = sortedParents?.map((parent) => ({
//     ...parent,
//     children: parentIdToVisibleChildren[parent.id].sort(
//       (a, b) => a.sortOrder - b.sortOrder
//     ),
//   }));

//   console.log("Nested Menu Items", nestedMenuItems);

//   // nestedMenuItems.push({
//   //   name: "OOH",
//   //   active: true,
//   //   icon: "table",
//   //   children: [
//   //     {
//   //       name: "Inventory",
//   //       url: "/ooh/inventory",
//   //       exact: true,
//   //       active: true,
//   //     },
//   //   ],
//   // });

//   // console.log(nestedMenuItems);

//   return {
//     nestedMenuItems: nestedMenuItems ?? [],
//     // isLoading
//   };
// };

import { useState } from "react";

export const useFilterAndSortMenuItems = () => {
  const menuData = JSON.parse(localStorage.getItem("menus"));

  // console.log(menuData);

  // Separate parent and child menu items
  const parents = menuData?.filter((menu) => menu.parentId === 0);
  const children = menuData?.filter((menu) => menu.parentId !== 0);

  // Map parent IDs to their children
  const parentIdToChildren = parents?.reduce((acc, parent) => {
    acc[parent.id] = children?.filter((child) => child.parentId === parent.id);
    return acc;
  }, {});

  // console.log("Parent Id to Children", parentIdToChildren);

  // Include all parents and their respective children
  const visibleParents = parents;

  // Map parent IDs to their visible children
  const parentIdToVisibleChildren = visibleParents?.reduce((acc, parent) => {
    acc[parent.id] = parentIdToChildren[parent.id] ?? [];
    return acc;
  }, {});

  // Sort the parent items
  const sortedParents = visibleParents?.sort(
    (a, b) => a.sortOrder - b.sortOrder
  );

  // Nest the child items under their respective parents
  const nestedMenuItems = sortedParents?.map((parent) => ({
    ...parent,
    children: parentIdToVisibleChildren[parent.id].sort(
      (a, b) => a.sortOrder - b.sortOrder
    ),
  }));

  // console.log("Nested Menu Items", nestedMenuItems);

  return {
    nestedMenuItems: nestedMenuItems ?? [],
  };
};
