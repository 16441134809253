import { API_BASE } from "./Base";

export { API_BASE };

export const CategoriesAPI = new API_BASE("/Catalogue/Categories");
export const CategoriesByIdAPI = new API_BASE("/Catalogue/CategoriesById");
export const CategoriesGroupAPI = new API_BASE("/Catalogue/CategoryGroups");
export const CategoriesFileAPI = new API_BASE("/Catalogue/CategoryFile");
export const FieldSetsAPI = new API_BASE("/Catalogue/FieldSets");
export const CategoryFieldSetsAPI = new API_BASE(
  "/Catalogue/CategoryFieldSets"
);
export const VariantsAPI = new API_BASE("/Catalogue/Variants");
export const VariantsFilesAPI = new API_BASE("/Catalogue/VariantFiles");
export const CategoryVariantsAPI = new API_BASE("/Catalogue/CategoryVariants");
export const ProductsAPI = new API_BASE("/Catalogue/Products");

export const MenusAPI = new API_BASE("/Core/Menus");
export const LibraryAPI = new API_BASE("/Core/Library");
export const LibraryWithDetailsAPI = new API_BASE("/Core/LibraryWithDetails");
export const ContactAPI = new API_BASE("/Core/Contact");
export const ContactsAPI = new API_BASE("/Core/Contacts");
export const RolesAPI = new API_BASE("/Administration/Roles");
export const RoleAPI = new API_BASE("/Administration/Role");
export const UpdateProfileAPI = new API_BASE("/Administration/UpdateProfile");
export const RolePermissionsAPI = new API_BASE(
  "/Administration/RolePermissions"
);
export const ForgetPasswordAPI = new API_BASE("/Authentication/Forgot");
export const InventoryAPI = new API_BASE("/OOH/AdInventory");
export const BlogPostsAPI = new API_BASE("/Core/BlogPosts");
export const EstimatesAPI = new API_BASE("/Core/Estimates");
export const CustomersAPI = new API_BASE("/Core/Customers");
export const CustomersEstimatesAPI = new API_BASE("/Core/CustomerEstimates");
export const UpdateEstimatesAPI = new API_BASE("/Core/UpdateEstimates");
