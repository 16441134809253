import { yupResolver } from "@hookform/resolvers/yup";
import { BlogPostsAPI, CategoryFieldSetsAPI, VariantsAPI } from "api";
import BreadCrumb from "components/breadcrumb/BreadCrumb";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { customSettings } from "utils/customConfig";
import * as yup from "yup";
import PostBasicInformation from "./PostBasicInformation";
import moment from "moment";

const schema = yup
  .object({
    title: yup.string().required("Title is required"),
    link: yup.string(),
    contentDetail: yup.string(),
    categoryGroupId: yup.string().required("Category group is required"),
    tags: yup.array().of(yup.string()),
    author: yup.string(),
    postDate: yup.string().required("Date is required"),
    sortOrder: yup.string().required("Display order is required"),
    backgroundImage: yup.string(),
    isPublished: yup.boolean(),
    status: yup.boolean(),
  })
  .required();

const AddPost = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: { backgroundImage: "", sortOrder: "", contentDetail: "" },
  });
  const { handleSubmit, reset, setValue } = methods;

  const onSubmit = async (data) => {
    let myData = {
      ...data,
      tags: data.tags.join("|"), // Convert the array to a string
      backgroundImage: data?.backgroundImage ? data?.backgroundImage : "",
      id: location?.state?.id ?? 0, // Use nullish coalescing to handle undefined or null
    };

    console.log(myData);

    try {
      if (location?.state) {
        setLoading(true);
        await BlogPostsAPI.post(myData);
        setLoading(false);
        toast.success("Blog Post Updated Successfully", {
          position: customSettings.toastPosition,
          autoClose: customSettings.toastClose,
        });
      } else {
        setLoading(true);
        await BlogPostsAPI.post(myData);
        setLoading(false);
        toast.success("Blog Post Created Successfully", {
          position: customSettings.toastPosition,
          autoClose: customSettings.toastClose,
        });
      }

      // ------- Get all object keys form data and set empty values to reset ------------
      const keys = Object.keys(data);
      for (const key of keys) {
        submittedValues[key] = "";
      }
      reset({ ...submittedValues });
      navigate(-1);
    } catch (error) {
      toast.error("Something Went Wrong", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
      console.log(error);
    }
  };

  useEffect(() => {
    if (location.state) {
      const {
        title,
        categoryGroupId,
        author,
        contentDetail,
        isPublished,
        link,
        sortOrder,
        status,
        tags,
        backgroundImage,
      } = location.state;
      let d = moment.utc(location.state?.postDate); // Parse in UTC
      const formattedDate = d.format("YYYY-MM-DD");

      setTimeout(() => {
        setValue("postDate", formattedDate);
      }, 200);

      setValue("title", title);
      setValue("categoryGroupId", categoryGroupId);
      setValue("author", author);
      setValue("contentDetail", contentDetail);
      setValue("isPublished", isPublished);
      setValue("link", link);
      setValue("sortOrder", sortOrder);
      setValue("status", status);
      setValue("tags", tags);
      setValue("backgroundImage", backgroundImage);
      // delete location.state.postDate;
      // reset(location.state);
    }
  }, []);

  return (
    <>
      {/* <div className="py-2">
        <BreadCrumb />
      </div> */}
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <Card.Header className="border-bottom">
              <Row className="flex-between-center ">
                <Col md>
                  <h5 className="fs-9 text-nowrap mb-2 mb-md-0">
                    {" "}
                    Blog | Posts
                  </h5>
                </Col>
                <Col xs="auto">
                  <Button
                    variant="link"
                    className="text-secondary fw-medium p-0 me-3"
                    type="button"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit" disabled={loading}>
                    Ok
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <PostBasicInformation />
            {/* <Row className="">
            
              <Col lg={12}>
              </Col> */}
            {/* <Col xs={12}>
    
            </Col> */}
            {/* </Row> */}
          </Card>
        </Form>
      </FormProvider>
    </>
  );
};

export default AddPost;
