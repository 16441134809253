import { createContext, useContext, useState } from "react";

const TabContext = createContext();

export const TabProvider = ({ children }) => {
  const [key, setKey] = useState("detail");

  return (
    <TabContext.Provider value={{ key, setKey }}>
      {children}
    </TabContext.Provider>
  );
};

export const useTab = () => useContext(TabContext);
