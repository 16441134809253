import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AuthSimpleLayout from "../layouts/AuthSimpleLayout";
import ErrorLayout from "../layouts/ErrorLayout";
import MainLayout from "../layouts/MainLayout";

import Error404 from "components/errors/Error404";
import Error500 from "components/errors/Error500";

import SimpleForgetPassword from "components/authentication/simple/ForgetPassword";
import SimpleLogin from "components/authentication/simple/Login";
import SimplePasswordReset from "components/authentication/simple/PasswordReset";

import Dashboard from "components/dashboards/default";

import WizardAuth from "components/authentication/wizard/WizardAuth";
import AddPost from "components/modules/blog/posts/add-post/AddPost";
import PostsTable from "components/modules/blog/posts/PostsTable";
import CustomersTable from "components/modules/customers/CustomersTable";
import CustomerDetails from "components/modules/customers/details/CustomerDetails";
import CustomerEstimateDetails from "components/modules/customers/details/CustomerEstimateDetails";
import CustomerEstimatesTable from "components/modules/customers/details/CustomerEstimatesTable";
import EstimateDetail from "components/modules/estimates/details/EstimateDetail";
import EstimatesTable from "components/modules/estimates/EstimatesTable";
import CategoriesPage from "components/modules/libraries-menu/Categories/CategoriesPage";
import AddCategoryFieldSet from "components/modules/libraries-menu/CategoryFieldSets/add-category-fieldset/AddCategoryFieldSet";
import CategoryFieldSetsTable from "components/modules/libraries-menu/CategoryFieldSets/CategoryFieldSetsTable";
import AddCategoryGroup from "components/modules/libraries-menu/CategoryGroups/add-category-group/AddCategoryGroup";
import CategoryGroupsTable from "components/modules/libraries-menu/CategoryGroups/CategoryGroupsTable";
import AddCategoryVariants from "components/modules/libraries-menu/CategoryVariants/add-category-variants/AddCategoryVariants";
import CategoryVariantsTable from "components/modules/libraries-menu/CategoryVariants/CategoryVariantsTable";
import FieldSetsForm from "components/modules/libraries-menu/FieldSets/add-fieldset/FieldSetsForm";
import FieldSetTable from "components/modules/libraries-menu/FieldSets/FieldSetsTable";
import GetAnEstimate from "components/modules/libraries-menu/GetAnEstimate/GetAnEstimate";
import AddProduct from "components/modules/libraries-menu/Products/add-product/AddProduct";
import ProductsTable from "components/modules/libraries-menu/Products/ProductsTable";
import AddVariants from "components/modules/libraries-menu/Variants/add-variants/AddVariants";
import VariantsTable from "components/modules/libraries-menu/Variants/VariantsTable";
import Profile from "components/pages/user/profile/Profile";
import Wizard from "components/wizard/Wizard";
import AuthGuard from "./Gaurd/AuthGaurd";
export const CONSTANT_ROUTES = {
  AUTHENTICATION_LOGIN: "/login",
  AUTHENTICATION_REGISTER: "/register",
  AUTHENTICATION_RESET_PASSWORD: "/reset-password",
  AUTHENTICATION_FORGOT_PASSWORD: "/forgot-password",
  USERPROFILE: "/userProfile",
  HOME: "/",
  CATEGORIES_PAGE: "/libraries/categories",
  CATEGORY_GROUPS_PAGE: "/libraries/category-groups",
  CATEGORY_GROUPS_ADD: "/libraries/category-groups/add-new",
  FIELD_SETS_TABLE: "/libraries/fieldSets",
  FIELD_SETS_ADD: "/libraries/fieldSets/add-new",
  CATEGORY_FIELDSET_TABLE: "/libraries/category-fieldset",
  CATEGORY_FIELDSET_ADD: "/libraries/category-fieldset/add-new",
  CATEGORY_VARIANTS_TABLE: "/libraries/category-variants",
  CATEGORY_VARIANTS_ADD: "/libraries/category-variants/add-new",
  PRODUCTS_TABLE: "/libraries/products",
  PRODUCTS_ADD: "/libraries/products/add-new",
  VARIANTS_TABLE: "/libraries/variants",
  VARIANTS_ADD: "/libraries/variants/add-new",
  BLOG_POSTS_TABLE: "/blog/posts",
  BLOG_POSTS_ADD: "/blog/posts/add-new",
  CUSTOMERS_TABLE: "/customers",
  CUSTOMER_TABLE_DETAIL: "/customers/detail",
  ESTIMATES_TABLE: "/estimates",
  ESTIMATES_TABLE_DETAILS: "/estimates/detail",
  CUSTOMER_ESTIMATE_TABLE: "/customer-estimates",
  CUSTOMER_ESTIMATE_DETAILS: "/customer-estimates/detail",
};

const FalconRoutes = () => {
  return (
    <Routes>
      {/* <Route path="landing" element={<Landing />} /> */}
      <Route element={<ErrorLayout />}>
        <Route path="errors/404" element={<Error404 />} />
        <Route path="errors/500" element={<Error500 />} />
      </Route>
      {/*- ------------- Authentication ---------------------------  */}

      {/*- ------------- simple ---------------------------  */}
      <Route element={<AuthSimpleLayout />}>
        <Route
          path={CONSTANT_ROUTES.AUTHENTICATION_LOGIN}
          element={<SimpleLogin />}
        />
        {/* <Route
          path="authentication/register"
          element={<SimpleRegistration />}
        /> */}
        <Route
          path={CONSTANT_ROUTES.AUTHENTICATION_FORGOT_PASSWORD}
          element={<SimpleForgetPassword />}
        />

        <Route
          path={CONSTANT_ROUTES.AUTHENTICATION_RESET_PASSWORD}
          element={<SimplePasswordReset />}
        />
        {/* <Route
          path="authentication/lock-screen"
          element={<SimpleLockScreen />}
        />
        <Route path="authentication/logout" element={<SimpleLogout />} />
       
   
        <Route
          path="authentication/confirm-mail"
          element={<SimpleConfirmMail />}
        /> */}
      </Route>

      {/* //--- MainLayout Starts  */}

      <Route element={<AuthGuard />}>
        <Route element={<MainLayout />}>
          {/*Dashboard*/}
          <Route path="/" element={<Dashboard />} />

          {/* =============Libraries=============== */}
          {/* <Route path="/libraries/categories" element={<CategoriesTable />} />
          <Route
            path="/libraries/categories/add-new-category"
            element={<AddCategory />}
          /> */}
          <Route
            path={CONSTANT_ROUTES.CATEGORIES_PAGE}
            element={<CategoriesPage />}
          />
          <Route
            path={CONSTANT_ROUTES.CATEGORY_GROUPS_PAGE}
            element={<CategoryGroupsTable />}
          />
          <Route
            path={CONSTANT_ROUTES.CATEGORY_GROUPS_ADD}
            element={<AddCategoryGroup />}
          />
          <Route
            path={CONSTANT_ROUTES.FIELD_SETS_TABLE}
            element={<FieldSetTable />}
          />
          <Route
            path={CONSTANT_ROUTES.FIELD_SETS_ADD}
            element={<FieldSetsForm />}
          />
          <Route
            path={CONSTANT_ROUTES.CATEGORY_FIELDSET_TABLE}
            element={<CategoryFieldSetsTable />}
          />
          <Route
            path={CONSTANT_ROUTES.CATEGORY_FIELDSET_ADD}
            element={<AddCategoryFieldSet />}
          />
          <Route
            path={CONSTANT_ROUTES.CATEGORY_VARIANTS_TABLE}
            element={<CategoryVariantsTable />}
          />
          <Route
            path={CONSTANT_ROUTES.CATEGORY_VARIANTS_ADD}
            element={<AddCategoryVariants />}
          />
          <Route
            path={CONSTANT_ROUTES.VARIANTS_TABLE}
            element={<VariantsTable />}
          />
          <Route
            path={CONSTANT_ROUTES.VARIANTS_ADD}
            element={<AddVariants />}
          />
          <Route
            path={CONSTANT_ROUTES.PRODUCTS_TABLE}
            element={<ProductsTable />}
          />
          <Route path={CONSTANT_ROUTES.PRODUCTS_ADD} element={<AddProduct />} />
          <Route path={CONSTANT_ROUTES.BLOG_POSTS_ADD} element={<AddPost />} />
          <Route
            path={CONSTANT_ROUTES.BLOG_POSTS_TABLE}
            element={<PostsTable />}
          />
          <Route
            path={CONSTANT_ROUTES.ESTIMATES_TABLE}
            element={<EstimatesTable />}
          />
          <Route
            path={CONSTANT_ROUTES.ESTIMATES_TABLE_DETAILS}
            element={<EstimateDetail />}
          />
          <Route
            path={CONSTANT_ROUTES.CUSTOMERS_TABLE}
            element={<CustomersTable />}
          />
          <Route
            path={CONSTANT_ROUTES.CUSTOMER_TABLE_DETAIL}
            element={<CustomerDetails />}
          />
          <Route
            path={CONSTANT_ROUTES.CUSTOMER_ESTIMATE_TABLE}
            element={<CustomerEstimatesTable />}
          />
          <Route
            path={CONSTANT_ROUTES.CUSTOMER_ESTIMATE_DETAILS}
            element={<CustomerEstimateDetails />}
          />
          {/* <Route path={CONSTANT_ROUTES.PRODUCTS_ADD} element={<AddProduct />} /> */}

          {/* ===============Libraries End================ */}

          {/*============================Administration=========================  */}

          {/* <Route path="/groups" element={<GroupsTable />} />
          <Route path="/groups/add-new-group" element={<AddGroup />} />
          <Route
            path="/administration/roles-and-permissions"
            element={<RolesAndPermissionsTable />}
          />
          <Route
            path="/administration/roles-and-permissions/add-new-role"
            element={<AddRoleAndPermission />}
          />
          <Route
            path="/administration/roles-and-permissions/assignment"
            element={<AddAssignment />}
          />
          <Route path="/administration/users" element={<UsersTable />} />
          <Route
            path="/administration/users/add-new-user"
            element={<AddUser />}
          /> */}
          {/* <Route path="/infinite-scroll" element={<AgGridInfiniteScroll />} />
          <Route path="/map" element={<GoogleMapExample />} /> */}

          {/*============================Administration End=========================  */}

          {/*=======Pages  ======*/}

          <Route path={CONSTANT_ROUTES.USERPROFILE} element={<Profile />} />
          {/* <Route path={"/calender"} element={<Calendar />} />
          <Route path={"/timeline"} element={<Timeline />} />
          <Route path={"/tree-view"} element={<TreeViewFalconExample />} />
          <Route path={"/dynamic-form"} element={<DynamicForm />} />
          <Route path={"/dnd-example"} element={<DndExampleComponent />} /> */}
          <Route element={<WizardAuth />}>
            <Route path="/home/wizard" element={<Wizard validation={true} />} />
            <Route
              path="/home/get-an-estimate"
              element={<GetAnEstimate validation={true} />}
            />
          </Route>
        </Route>
      </Route>

      {/* //--- MainLayout end  */}

      {/* <Navigate to="/errors/404" /> */}
      <Route path="*" element={<Navigate to="/errors/404" replace />} />
    </Routes>
  );
};

export default FalconRoutes;
