import { useEffect, useRef } from "react";
import { components } from "react-select"

const CustomOption = (props) => {
    const ref = useRef();

    // Scroll to the option when it's selected
    useEffect(() => {
        if (props.isSelected) {
            ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, [props.isSelected]);

    return <components.Option {...props} innerRef={ref} />;
};

export default CustomOption;