import React from "react";

import NotificationDropdown from "components/navbar/top/NotificationDropdown";
import ProfileDropdown from "components/navbar/top/ProfileDropdown";
import { Nav } from "react-bootstrap";
import NineDotMenu from "./NineDotMenu";
import ThemeControlDropdown from "./ThemeControlDropdown";
import LanguageControlDropdown from "./LanguageControlDropdown";

const TopNavRightSideNavItem = () => {
  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
      style={{ height: "5px" }}
    >
      <LanguageControlDropdown />
      <ThemeControlDropdown />
      <NotificationDropdown />
      {/* <NineDotMenu /> */}
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
