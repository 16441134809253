import React from "react";
import { useDroppable } from "@dnd-kit/core";

const Droppable = ({ children }) => {
  const { isOver, setNodeRef } = useDroppable({
    id: "droppable",
  });

  const style = {
    border: isOver ? "2px solid green" : "2px dashed gray",
    // minHeight: "650px",
    minHeight: `calc(100vh - 5.6rem)`,
    width: "100%",
    backgroundColor: isOver ? "#f0f8ff" : "#f9f9f9",
  };

  return (
    <div ref={setNodeRef} style={style}>
      {children}
    </div>
  );
};

export default Droppable;
