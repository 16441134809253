import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { CONSTANT_ROUTES } from "routes";
import * as yup from "yup";
import CustomerEstimatesInformation from "./CustomerEstimateInformation";
import { UpdateEstimatesAPI } from "api";
import { toast } from "react-toastify";
import { customSettings } from "utils/customConfig";
import Swal from "sweetalert2";
import { useTab } from "../context/TabContext";

const schema = yup.object({
  firstName: yup.string(),
  lastName: yup.string(),
  emailAddress: yup.string(),
  landmark: yup.string(),
  password: yup.string(),
  streetAddress: yup.string(),
  city: yup.string(),
  birthDate: yup.string(),
  postalCode: yup.string(),
  country: yup.string(),
  state: yup.string(),
});

const CustomerEstimateDetails = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const { key, setKey } = useTab();

  const [statusValue, setStatusValue] = useState(state?.estimateStatus || "");

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const { reset, handleSubmit, setValue } = methods;

  const onSubmit = async () => {
    if (statusValue === state?.estimateStatus) {
      navigate(-1);
      return;
    }
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to change status",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    });

    if (result.isConfirmed) {
      try {
        const response = await UpdateEstimatesAPI.postStatus({
          params: { estimateId: state?.id, status: statusValue },
        });

        if (response.data?.data === true) {
          // Update the local state with the new status
          toast.success("Status Updated Successfully", {
            position: customSettings.toastPosition,
            autoClose: customSettings.toastClose,
          });
          navigate(-1); // Navigate back
        }
      } catch (error) {
        console.error(error);
        toast.error("Something went wrong", {
          position: customSettings.toastPosition,
          autoClose: customSettings.toastClose,
        });
      }
    }
  };

  const handleBack = () => {
    setKey("estimates");
    navigate(-1);
  };

  useEffect(() => {
    if (state) {
      // console.log(state);
      reset(state);
    }
  }, [state]);

  return (
    <>
      {/* <div className="py-2">
        <BreadCrumb />
      </div> */}
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Card>
            <Card.Header className="border-bottom">
              <Row className="flex-between-center ">
                <Col md>
                  <h5 className="fs-9 text-nowrap mb-2 mb-md-0">
                    {" "}
                    Customers | {state?.userId || "Detail"} | Estimates |{" "}
                    {state?.planName || "Detail"}
                  </h5>
                </Col>
                <Col xs="auto">
                  <Button
                    variant="link"
                    className="text-secondary fw-medium p-0 me-3"
                    type="button"
                    onClick={handleBack}
                  >
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit">
                    Ok
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <CustomerEstimatesInformation
              statusValue={statusValue}
              setStatusValue={setStatusValue}
              setKey={setKey}
            />
          </Card>
        </Form>
      </FormProvider>
    </>
  );
};

export default CustomerEstimateDetails;
