export const saveColumnState = (tableKey, columnApiRef) => {
  if (!columnApiRef?.current) {
    console.error("Column API is not initialized.");
    return;
  }

  const colState = columnApiRef.current.api.getColumnState();

  // Save only the column state to localStorage
  const tablesState = getLocalStorage("tablesState") || {};
  tablesState[tableKey] = colState;

  setLocalStorage("tablesState", tablesState);
  console.log(`Column state saved for table: ${tableKey}`);
};

export const restoreColumnState = (tableKey, columnApiRef) => {
  if (!columnApiRef?.current) {
    console.error("Column API is not initialized.");
    return;
  }

  const tablesState = getLocalStorage("tablesState");

  if (!tablesState || !tablesState[tableKey]) {
    console.log(`No column state found for table: ${tableKey}`);
    return;
  }

  // Restore only the column state
  columnApiRef.current.api.applyColumnState({
    state: tablesState[tableKey],
    applyOrder: true,
  });

  console.log(`Column state restored for table: ${tableKey}`);
};

export const resetColumnState = (tableKey, columnApiRef) => {
  if (!columnApiRef?.current) {
    console.error("Column API is not initialized.");
    return;
  }

  // Reset column state to the default configuration
  columnApiRef.current.api.resetColumnState();

  // Remove the column state from localStorage
  const tablesState = getLocalStorage("tablesState") || {};
  if (tablesState[tableKey]) {
    delete tablesState[tableKey];
    setLocalStorage("tablesState", tablesState);
    console.log(
      `Column state reset and removed from storage for table: ${tableKey}`
    );
  } else {
    console.log(`No saved column state found for table: ${tableKey}`);
  }
};

const setLocalStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

const getLocalStorage = (key) => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : null;
};

const clearLocalStorage = (key) => {
  localStorage.removeItem(key);
};
