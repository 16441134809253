import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import avatar from "assets/img/team/avatar.png";
import Avatar from "components/common/Avatar";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "Main";
import { CONSTANT_ROUTES } from "../../../routes";

const ProfileDropdown = () => {
  const navigate = useNavigate();
  const { logout } = useAppContext();

  const handleLogout = async () => {
    await logout();
    navigate(CONSTANT_ROUTES.AUTHENTICATION_LOGIN);
  };

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={avatar} size="l" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          {/* <Dropdown.Item className="fw-bold text-warning" href="#!">
            <FontAwesomeIcon icon="crown" className="me-1" />
            <span>Go Pro</span>
          </Dropdown.Item> */}
          {/* <Dropdown.Divider /> */}
          {/* <Dropdown.Item href="#!">Set status</Dropdown.Item> */}
          <Dropdown.Item as={Link} to={CONSTANT_ROUTES.USERPROFILE}>
            Profile &amp; account
          </Dropdown.Item>
          {/* <Dropdown.Item href="#!">Feedback</Dropdown.Item>
          <Dropdown.Divider /> */}
          {/* <Dropdown.Item as={Link} to="/user/settings">
            Settings
          </Dropdown.Item> */}
          <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
