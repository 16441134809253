export const formatDateWithCapitalizedMonth = (
  dateString,
  locale = "fr-FR"
) => {
  if (!dateString) return null;

  const options = { day: "2-digit", month: "long", year: "numeric" };
  const formattedDate = new Date(dateString).toLocaleDateString(
    locale,
    options
  );
  const parts = formattedDate.split(" ");

  // Capitalize the first letter of the month (2nd part in the date string)
  parts[1] = parts[1].charAt(0).toUpperCase() + parts[1].slice(1);

  return parts.join(" ");
};
