import { yupResolver } from "@hookform/resolvers/yup";
import { CategoryFieldSetsAPI, CategoryVariantsAPI } from "api";
import BreadCrumb from "components/breadcrumb/BreadCrumb";
import React, { useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { customSettings } from "utils/customConfig";
import * as yup from "yup";
import CategoryVariantsBasicInformation from "./CategoryVariantsBasicInformation";

const schema = yup
  .object({
    categoryId: yup.string().required("Category is required"),
    name: yup.string().required("Name is required"),
    options: yup.array().of(
      yup.object().shape({
        variantId: yup.string().required("Variant is required"),
        sortOrder: yup.string(),
        status: yup.boolean(),
      })
    ),
    status: yup.boolean(),
  })
  .required();

const AddCategoryVariants = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      options: [{}],
    },
  });
  const { handleSubmit, reset, setValue } = methods;

  const onSubmit = async (data) => {
    let myData = {
      ...data,
      id: location?.state?.id ?? 0, // Use nullish coalescing to handle undefined or null
      options: data.options?.map((d) => {
        return {
          ...d,
          sortOrder: 0,
        };
      }),
    };

    // console.log(myData);

    try {
      if (location?.state) {
        await CategoryVariantsAPI.post(myData);
        toast.success("Category Variant Updated Successfully", {
          position: customSettings.toastPosition,
          autoClose: customSettings.toastClose,
        });
      } else {
        await CategoryVariantsAPI.post(myData);
        toast.success("Category Variant Created Successfully", {
          position: customSettings.toastPosition,
          autoClose: customSettings.toastClose,
        });
      }

      // ------- Get all object keys form data and set empty values to reset ------------
      const keys = Object.keys(data);
      for (const key of keys) {
        submittedValues[key] = "";
      }
      reset({ ...submittedValues });
      navigate(-1);
    } catch (error) {
      toast.error("Something Went Wrong", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
      console.log(error);
    }
  };

  useEffect(() => {
    if (location.state) {
      reset(location.state);
    }
  }, []);

  return (
    <>
      {/* <div className="py-2">
        <BreadCrumb />
      </div> */}
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <Card.Header className="border-bottom">
              <Row className="flex-between-center ">
                <Col md>
                  <h5 className="fs-9 text-nowrap mb-2 mb-md-0">
                    Libraries | Category Variants
                  </h5>
                </Col>
                <Col xs="auto" style={{ paddingRight: "15px" }}>
                  <Button
                    variant="link"
                    className="text-secondary fw-medium p-0 me-3"
                    type="button"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit">
                    Ok
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <CategoryVariantsBasicInformation />
            {/* <Row className="">
            
              <Col lg={12}>
              </Col> */}
            {/* <Col xs={12}>
    
            </Col> */}
            {/* </Row> */}
          </Card>
        </Form>
      </FormProvider>
    </>
  );
};

export default AddCategoryVariants;
