import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import CustomerInformation from "./CustomerInformation";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CONSTANT_ROUTES } from "routes";
import CustomerEstimatesInformation from "./CustomerEstimateInformation";
import CustomerEstimatesTable from "./CustomerEstimatesTable";
import { useTab } from "../context/TabContext";

const schema = yup.object({
  firstName: yup.string(),
  lastName: yup.string(),
  emailAddress: yup.string(),
  landmark: yup.string(),
  password: yup.string(),
  streetAddress: yup.string(),
  city: yup.string(),
  birthDate: yup.string(),
  postalCode: yup.string(),
  country: yup.string(),
  state: yup.string(),
});

const CustomerDetails = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const { key, setKey } = useTab();

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const { reset, setValue } = methods;

  const handleClick = (data) => {
    if (data) {
      navigate(`${CONSTANT_ROUTES.CUSTOMER_ESTIMATE_TABLE}`, { state: data });
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (state) {
      reset(state);
    }
  }, [state]);

  return (
    <>
      {/* <div className="py-2">
        <BreadCrumb />
      </div> */}
      <FormProvider {...methods}>
        <Form>
          <Card>
            <Card.Header className="border-bottom">
              <Row className="flex-between-center ">
                <Col md>
                  <h5 className="fs-9 text-nowrap mb-2 mb-md-0">
                    {" "}
                    Customers | {state?.emailAddress || "Detail"}
                  </h5>
                </Col>
                <Col xs="auto">
                  {/* <Button
                    variant="primary"
                    className="me-4"
                    onClick={() => handleClick(state?.emailAddress)}
                  >
                    Estimates
                  </Button> */}
                  <Button
                    variant="link"
                    className="text-secondary fw-medium p-0 me-3"
                    type="button"
                    onClick={handleBack}
                  >
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={() => navigate(-1)}>
                    Ok
                  </Button>
                </Col>
              </Row>
            </Card.Header>

            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="border-bottom"
            >
              <Tab
                eventKey="detail"
                title={"Detail"}
                style={{ padding: "3px 0rem" }}
              >
                <CustomerInformation />
              </Tab>
              <Tab
                eventKey="estimates"
                title={"Estimates"}
                style={{ padding: "0 1rem" }}
              >
                <CustomerEstimatesTable data={state?.emailAddress} />
              </Tab>
            </Tabs>
          </Card>
        </Form>
      </FormProvider>
    </>
  );
};

export default CustomerDetails;
