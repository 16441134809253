import SubtleBadge from "components/common/SubtleBadge";
import { useFetchCategories } from "hooks/useFetchCategories";
import useQuery from "hooks/useQuery";
import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  FloatingLabel,
  Form,
  InputGroup,
  Row,
  Spinner,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { MdOutlineLibraryAddCheck } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { customSettings } from "utils/customConfig";

const ProductGeneralInfo = () => {
  const {
    register,
    getValues,
    watch,
    clearErrors,
    setValue,
    formState: { errors },
  } = useFormContext();

  // console.log(categoryValue);

  const { state } = useLocation();
  const navigate = useNavigate();

  const [tags, setTags] = useState(getValues("tags") || []);
  const [inputValue, setInputValue] = useState("");

  const [data, setData] = useState([]);
  const [options, setOptions] = useState([]);

  const { data: categoriesData, isLoading: categoriesLoading } =
    useFetchCategories();

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: errors.categoryId ? "red" : provided.borderColor,
      "&:hover": {
        borderColor: errors.categoryId
          ? "red"
          : provided["&:hover"].borderColor,
      },
    }),

    input: (provided) => ({
      ...provided,
      color: "black",
      // paddingTop: "1px",
      // paddingBottom: "1px",
      // padding: "12px", // Set the input text color to black
    }),

    option: (provided, state) => ({
      ...provided,
      paddingLeft: `${state.data.level * 20}px`,
    }),

    menu: (provided) => ({
      ...provided,
      color: "black",
      zIndex: 9999, // Adjust the zIndex as needed
    }),
  };

  // useEffect(() => {
  //   if (categoriesData) {
  //     const hierarchicalData = createHierarchy(categoriesData?.data?.data);
  //     setData(hierarchicalData);
  //   }
  // }, [categoriesData]);

  // useEffect(() => {
  //   if (data.length > 0) {
  //     const flatOptions = flattenOptions(data);
  //     setOptions(flatOptions);
  //   }
  // }, [data]);

  const flattenOptions = (nodes, level = 1) => {
    return nodes.reduce((acc, node) => {
      const label = `${" ".repeat(level - 1)} ${node.name}`;
      acc.push({ value: node.id, label, parent: node.parent, level });

      if (node.children && node.children.length > 0) {
        acc.push(...flattenOptions(node.children, level + 1));
      }

      return acc;
    }, []);
  };

  const createHierarchy = (data) => {
    const lookup = {};
    const result = [];

    data?.forEach((item) => {
      lookup[item.id] = { ...item, children: [] };
    });

    data?.forEach((item) => {
      const { id, parent } = item;
      if (parent === 0) {
        result.push(lookup[id]);
      } else if (lookup[parent]) {
        lookup[parent].children.push(lookup[id]);
      }
    });

    return result;
  };

  const hierarchicalData = createHierarchy(categoriesData?.data?.data);
  const flatOptions = flattenOptions(hierarchicalData);

  const handleSelectChange = (selectedOption) => {
    console.log(selectedOption);
    setValue("categoryId", selectedOption ? selectedOption.value : "");
    if (selectedOption) {
      clearErrors("categoryId");
    }
  };
  useEffect(() => {
    register("categoryId", { required: true });
  }, [register]);

  useEffect(() => {
    if (state) {
      // const parsedTags = state.tags ? state.tags.split("|") : [];

      // setValue("tags", parsedTags); // Set tags as an array
      // setTags(parsedTags); // Set the parsed tags in local state
      setTags(state.productTags || []); // Set the parsed tags in local state
      setValue("categoryId", state.categoryId);
    }
  }, [state, setValue]);

  const handleInputFocus = () => {
    clearErrors("categoryId");
  };

  // console.log(options);

  let initialSelectedOption = flatOptions.find(
    (option) => option.value == state?.categoryId
  );

  if (initialSelectedOption == undefined) {
    initialSelectedOption = state
      ? { label: state?.categoryId, value: state?.categoryId }
      : undefined;
  }

  useEffect(() => {
    // Update form value for tags when local state changes
    setValue("tags", tags);
  }, [tags, setValue]);

  // if (categoriesLoading) {
  //   return <Spinner variant="primary" animation="border" />;
  // }

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && inputValue) {
      event.preventDefault();
      if (!tags.includes(inputValue.trim())) {
        setTags([...tags, inputValue.trim()]);
      }
      setInputValue("");
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const pricingAndStockWatch = watch("pricingAndStock");
  const permutationFound = pricingAndStockWatch?.length > 0;

  const handleApplySalePriceToAll = () => {
    const salePrice = watch("salesPrice");
    const categoryId = watch("categoryId");

    if (salePrice && categoryId && permutationFound) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want apply to all",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          const updatedInputs = pricingAndStockWatch.map((row) => ({
            ...row,
            price: salePrice,
          }));
          setValue("pricingAndStock", updatedInputs);
          // Swal.fire({
          //   title: "Deleted!",
          //   text: "Your file has been deleted.",
          //   icon: "success",
          // });
        }
      });
    } else if (!salePrice) {
      // Handle cases where salePrice or categoryId is not available
      toast.error("Sale Price is missing.", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
    } else if (!categoryId) {
      toast.error("Category is missing.", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
    } else {
      toast.error("Permutation is missing.", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
    }
  };

  return (
    <>
      {/* <Card.Header as="h6" className="bg-body-tertiary mb-2">
        General information
      </Card.Header> */}
      {categoriesLoading ? (
        <div className="d-flex py-3 justify-content-center align-items-center">
          <Button variant="falcon-default" size="sm" className="fs-10">
            Loading...
          </Button>
        </div>
      ) : (
        <Row className="gx-1 gy-1">
          <Col md="6">
            <Form.Group>
              {/* <FloatingLabel controlId="floatingInput" label="Name" className=""> */}
              <Form.Control
                type="text"
                placeholder="Name"
                title="Name"
                isInvalid={!!errors?.name}
                {...register("name")}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.name?.message}
              </Form.Control.Feedback>
              {/* </FloatingLabel> */}
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <div title="Select a Category">
                <Select
                  options={flatOptions}
                  isClearable
                  placeholder={
                    <h6 className="mb-2 mb-md-0">
                      Category{" "}
                      {/* <span style={{ color: "red", fontSize: "12px" }}>*</span> */}
                    </h6>
                  }
                  className={`${errors.categoryId ? "is-invalid" : ""}`}
                  styles={customStyles}
                  onChange={handleSelectChange}
                  onFocus={handleInputFocus}
                  onInputChange={handleInputFocus}
                  defaultValue={initialSelectedOption}
                />
              </div>
              <Form.Control.Feedback type="invalid">
                {errors.categoryId?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group>
              <Form.Select
                aria-label="type"
                type="text"
                title="Type"
                isInvalid={!!errors?.type}
                {...register("type")}
              >
                <option value="">Select Type</option>
                <option value="Storable">Storable</option>
                <option value="Service">Service</option>
                <option value="Consumable">Consumable</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.type?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md="6">
            <div className="form-control d-flex flex-wrap align-items-center">
              {tags.map((tag, index) => (
                <SubtleBadge key={index} className=" mr-2 mb-1 mx-1">
                  {tag}
                  <span
                    className="ml-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRemoveTag(tag)}
                  >
                    &times;
                  </span>
                </SubtleBadge>
              ))}
              <input
                type="text"
                title="Tags"
                className="border-0 flex-grow-1"
                style={{
                  outline: "none",
                  color: "black",
                  minWidth: "120px",
                  // paddingBottom: "10px",
                  // paddingTop: "10px",
                }}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Tags"
              />
            </div>
          </Col>

          <Col md="6">
            <InputGroup className="mb-3">
              <Form.Control
                type="number"
                placeholder="Sale Price"
                title="Sale Price"
                isInvalid={!!errors?.salesPrice}
                {...register("salesPrice")}
              />
              {/* <InputGroup.Text id="basic-addon2">Apply to All</InputGroup.Text> */}
              <OverlayTrigger
                overlay={
                  <Tooltip
                    style={{ position: "fixed" }}
                    id="overlay-trigger-example"
                  >
                    Apply to All
                  </Tooltip>
                }
              >
                <Button onClick={handleApplySalePriceToAll} variant="secondary">
                  <MdOutlineLibraryAddCheck />
                </Button>
              </OverlayTrigger>
              <Form.Control.Feedback type="invalid">
                {errors?.salesPrice?.message}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
          <Col md="6">
            <Form.Group>
              <Form.Control
                type="number"
                placeholder="Purchase Price [Cost]"
                title="Purchase Price [Cost]"
                isInvalid={!!errors?.cost}
                {...register("cost")}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.cost?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      )}
      {/* <Row className="gx-2 gy-3 my-1">
        <Col className="d-flex justify-content-end">
          <Button
            variant="link"
            className="text-secondary fw-medium p-0 me-3"
            type="button"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <Button variant="primary" onClick={() => setKey("costing")}>
            Next
          </Button>
        </Col>
      </Row> */}
    </>
  );
};

export default ProductGeneralInfo;
