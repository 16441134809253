import "helpers/initFA";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/primereact.min.css"; //core css
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import Main from "./Main";
import "./i18n"; // Import the i18n configuration

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { TabProvider } from "components/modules/customers/context/TabContext";

const container = document.getElementById("main");
const root = createRoot(container);
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <TabProvider>
        <Main>
          <App />
        </Main>
      </TabProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
