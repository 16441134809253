import { yupResolver } from "@hookform/resolvers/yup";
import {
  BlogPostsAPI,
  CategoryFieldSetsAPI,
  UpdateEstimatesAPI,
  VariantsAPI,
} from "api";
import BreadCrumb from "components/breadcrumb/BreadCrumb";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { customSettings } from "utils/customConfig";
import * as yup from "yup";
import EstimatesInformation from "./EstimatesInformation";
import moment from "moment";
import Swal from "sweetalert2";

const EstimateDetail = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);

  const [statusValue, setStatusValue] = useState(state?.estimateStatus || "");

  const handleSubmit = async () => {
    if (statusValue === state?.estimateStatus) {
      navigate(-1);
      return;
    }

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to change status",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    });

    if (result.isConfirmed) {
      try {
        const response = await UpdateEstimatesAPI.postStatus({
          params: { estimateId: state?.id, status: statusValue },
        });

        if (response.data?.data === true) {
          // Update the local state with the new status
          toast.success("Status Updated Successfully", {
            position: customSettings.toastPosition,
            autoClose: customSettings.toastClose,
          });
          navigate(-1); // Navigate back
        }
      } catch (error) {
        console.error(error);
        toast.error("Something went wrong", {
          position: customSettings.toastPosition,
          autoClose: customSettings.toastClose,
        });
      }
    }
  };

  return (
    <>
      <Form noValidate>
        <Card>
          <Card.Header className="border-bottom">
            <Row className="flex-between-center ">
              <Col md>
                <h5 className="fs-9 text-nowrap mb-2 mb-md-0">
                  {" "}
                  Estimates | {state?.userId}
                </h5>
              </Col>
              <Col xs="auto">
                <Button
                  variant="link"
                  className="text-secondary fw-medium p-0 me-3 "
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                  Ok
                </Button>
              </Col>
            </Row>
          </Card.Header>
          <EstimatesInformation
            setStatusValue={setStatusValue}
            statusValue={statusValue}
          />
        </Card>
      </Form>
    </>
  );
};

export default EstimateDetail;
