import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FalconCloseButton from "components/common/FalconCloseButton";
import Flex from "components/common/Flex";
import React, { useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFilterAndSortMenuItems } from "utils/menuUtils";

const flattenMenuItems = (menuItems) => {
  let flatItems = [];
  menuItems?.forEach((item) => {
    flatItems.push(item);
    if (item.children) {
      flatItems = flatItems.concat(flattenMenuItems(item.children));
    }
  });
  return flatItems;
};

const SearchBoxCustom = ({ autoCompleteItem }) => {
  const { nestedMenuItems, isLoading } = useFilterAndSortMenuItems();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [resultItem, setResultItem] = useState(autoCompleteItem);

  const [query, setQuery] = useState("");
  const history = useNavigate();
  const allMenuItems = flattenMenuItems(nestedMenuItems);

  const filterParent = allMenuItems?.filter((i) => i.parentId !== 0);

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  const handleSelect = (url) => {
    setQuery("");
    setDropdownOpen(false);
    history(url);
  };

  const filteredItems = filterParent?.filter((item) =>
    item.name.toLowerCase().includes(query.toLowerCase())
  );

  return (
    <>
      <Dropdown
        show={dropdownOpen}
        className="search-box"
        // style={{ padding: "0px" }}
        onToggle={() => setDropdownOpen(!dropdownOpen)}
      >
        <Dropdown.Toggle as="div" className="dropdown-caret-none">
          <Form className="position-relative">
            <Form.Control
              type="search"
              placeholder="Search..."
              aria-label="Search"
              style={{ paddingBottom: "1px", paddingTop: "1px" }}
              className=" search-input"
              value={query}
              onChange={handleSearch}
            />
            <FontAwesomeIcon
              icon="search"
              className="position-absolute text-400 search-box-icon"
            />
            {query && (
              <div className="search-box-close-btn-container">
                <FalconCloseButton
                  size="sm"
                  noOutline
                  className="fs-11"
                  onClick={() => {
                    setQuery("");
                    setDropdownOpen(false);
                  }}
                />
              </div>
            )}
          </Form>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <div className="scrollbar py-3" style={{ maxHeight: "24rem" }}>
            {query ? (
              <>
                {filteredItems.map((item, index) => (
                  <Dropdown.Item
                    as={Link}
                    to={item.url}
                    className=" fs-10 px-x1 pt-0 hover-primary"
                    key={item.id}
                  >
                    <Flex alignItems="center">
                      <div className="fw-medium">{item.name}</div>
                    </Flex>
                  </Dropdown.Item>
                ))}

                {filteredItems.length === 0 && (
                  <li
                    className="fs-10"
                    style={{ padding: "2px 12px", color: "#aaa" }}
                  >
                    No results found
                  </li>
                )}
              </>
            ) : (
              <>
                {filteredItems.map((item, index) => (
                  <Dropdown.Item
                    as={Link}
                    to={item.url}
                    className="px-x1 pt-0 hover-primary"
                    key={item.id}
                  >
                    <Flex alignItems="center">
                      <div className="fw-medium">{item.name}</div>
                    </Flex>
                  </Dropdown.Item>
                ))}
              </>
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default SearchBoxCustom;
