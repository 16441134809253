import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

/**
 * Custom hook to prompt a confirmation dialog before navigating away from the page.
 * @param {boolean} isFormDirty - Indicates if the form has unsaved changes or not.
 */
export const useNavigationGuard = (isFormDirty) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        event.preventDefault();
        event.returnValue = ""; // Required for Chrome to show the dialog
      }
    };

    const handleNavigation = (event) => {
      if (
        isFormDirty &&
        !window.confirm(
          "You have unsaved changes, are you sure you want to leave?"
        )
      ) {
        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handleNavigation);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handleNavigation);
    };
  }, [isFormDirty, navigate, location]);
};
