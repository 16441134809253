import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import Logo from "components/common/Logo";
import Section from "components/common/Section";
import { Outlet } from "react-router-dom";
import bg from "../assets/img/myImages/bg.jpg";
import logo from "assets/img/logos/logoNew.png";
const AuthSimpleLayout = () => {
  return (
    <Section
      className="py-0"
      style={{ backgroundImage: `url(${bg})`, backgroundSize: "cover" }}
    >
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={10} md={8} lg={6} xl={5} style={{ width: "395px" }}>
          <Card
            style={{
              borderRadius: "5px",
              boxShadow: "0px 4px 23px rgba(0, 0, 0, 0.14)",
            }}
            className="border"
          >
            <div className="d-flex justify-content-center mt-2">
              <img
                src={logo}
                alt="Logo"
                width={50}
                style={{ marginLeft: "2px" }}
              />
            </div>
            <Card.Body className="p-4 p-sm-4">
              <Outlet />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};

export default AuthSimpleLayout;
