import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { MdErrorOutline } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { CONSTANT_ROUTES } from "routes";

const CustomerInformation = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const [loading, setLoading] = useState(false);

  const maxCharactersLength = 255;
  // console.log(state);

  return (
    <>
      {loading ? (
        <div className="d-flex py-3 justify-content-center align-items-center">
          <Button variant="falcon-default" size="sm" className="fs-10">
            Loading...
          </Button>
        </div>
      ) : (
        <Card.Body
          className=""
          style={{
            height: `calc(100vh - 12.7rem)`,
            overflow: "auto",
            padding: "5px 20px",
          }}
        >
          <Row className="gx-2 pb-2">
            <Col md="6">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Firstname"
                  title="Firstname"
                  maxLength={maxCharactersLength}
                  className="py-2 "
                  isInvalid={!!errors.firstName}
                  {...register("firstName")}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-inline w-75"
                  >
                    {errors.firstName?.message}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Last name"
                  title="Last name"
                  maxLength={maxCharactersLength}
                  className="py-2 "
                  isInvalid={!!errors.lastName}
                  {...register("lastName")}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-inline w-75"
                  >
                    {errors.lastName?.message}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Email"
                  title="Email"
                  maxLength={maxCharactersLength}
                  className="py-2 "
                  isInvalid={!!errors.emailAddress}
                  {...register("emailAddress")}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-inline w-75"
                  >
                    {errors.emailAddress?.message}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Telephone"
                  title="Telephone"
                  maxLength={maxCharactersLength}
                  className="py-2 "
                  isInvalid={!!errors.phoneNumber}
                  {...register("phoneNumber")}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-inline w-75"
                  >
                    {errors.phoneNumber?.message}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Address"
                  title="Address"
                  maxLength={maxCharactersLength}
                  className="py-2 "
                  isInvalid={!!errors.streetAddress}
                  {...register("streetAddress")}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-inline w-75"
                  >
                    {errors.streetAddress?.message}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </Col>
            {/* <Col md="6">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="City"
                  title="City"
                  maxLength={maxCharactersLength}
                  className="py-2 "
                  isInvalid={!!errors.city}
                  {...register("city")}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-inline w-75"
                  >
                    {errors.city?.message}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </Col> */}
            <Col md="6">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Postal Code"
                  title="Postal Code"
                  maxLength={maxCharactersLength}
                  className="py-2 "
                  isInvalid={!!errors.postalCode}
                  {...register("postalCode")}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-inline w-75"
                  >
                    {errors.postalCode?.message}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Country"
                  title="Country"
                  maxLength={maxCharactersLength}
                  className="py-2 "
                  isInvalid={!!errors.country}
                  {...register("country")}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-inline w-75"
                  >
                    {errors.country?.message}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="City"
                  title="City"
                  maxLength={maxCharactersLength}
                  className="py-2 "
                  isInvalid={!!errors.city}
                  {...register("city")}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-inline w-75"
                  >
                    {errors.city?.message}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      )}
    </>
  );
};

export default CustomerInformation;
